import { useLocation } from 'react-router-dom';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@constants/project';
import { useLogin } from './queries';
import { Form, Space, Row } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { LoginPagerWrapper, LoginSection, LoginLogo, LoginInput, PasswordInput, Buttons } from './styles';
import { AsteriskText } from '@components/common/styles';

type locationState = {
  from: {
    pathname: string;
  };
};

const LoginPage = () => {
  const [form] = Form.useForm();
  const { state } = useLocation();
  const from =
    state === null ? `/project?page=${DEFAULT_PAGE}&size=${DEFAULT_PAGE_SIZE}` : (state as locationState).from.pathname;
  const { mutate: mutateLogin } = useLogin();

  const handleLogin = async () => {
    const { email, password } = await form.validateFields();
    mutateLogin({ email, password });
  };

  return (
    <>
      <LoginPagerWrapper>
        <LoginSection>
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Row justify="center">
              <LoginLogo></LoginLogo>
            </Row>
            <Form style={{ width: '100%' }} form={form} name="login" onFinish={handleLogin}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: '이메일를 입력하세요.',
                  },
                ]}
              >
                <LoginInput addonBefore={<UserOutlined></UserOutlined>} placeholder="이메일" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: '비밀번호를 입력하세요.',
                  },
                ]}
              >
                <PasswordInput addonBefore={<LockOutlined></LockOutlined>} placeholder="비밀번호" />
              </Form.Item>

              <Form.Item>
                <Space direction="vertical" style={{ width: '100%' }} size="middle">
                  <Buttons type="primary" htmlType="submit">
                    로그인
                  </Buttons>
                </Space>
              </Form.Item>
            </Form>
          </Space>
          <Space direction="vertical" align="center">
            <AsteriskText asterisk={true}>이 사이트는 크롬에 최적화되어 있습니다.</AsteriskText>
            <AsteriskText>CROSSBIM Inc. All Rights Reserved</AsteriskText>
          </Space>
        </LoginSection>
      </LoginPagerWrapper>
    </>
  );
};

export default LoginPage;
